<template>
  <number-animator
    :min="prevValue"
    :max="nextValue"
    ref="animator"
    :duration="duration"
    :tag="tag"
    v-slot="{ targetNumber }"
  >
    <slot :targetNumber="targetNumber"> </slot>
  </number-animator>
</template>

<script>
import NumberAnimator from './NumberAnimator.vue';

/**
 * @description
 * 값이 바뀔 때마다 증감 여부에 따라 자동으로 애니메이션 효과를 넣어준다.
 * @example
 * <AutoNumberAnimator :value="myValue" v-slot="{targetNumber}">
 *  {{targetNumber}}
 * </AutoNumberAnimator>
 */

export default {
  name: 'AutoNumberAnimator',
  components: { NumberAnimator },
  data: () => ({
    prevValue: 0,
    nextValue: 0,
  }),
  props: {
    value: {
      Type: Number,
      default: 0,
    },
    duration: {
      Type: Number,
      default: 300,
    },
    tag: {
      default: 'div',
    },
  },
  watch: {
    value(value, oldValue) {
      this.prevValue = oldValue;
      this.nextValue = value;
      this.$refs.animator.onStart();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value === 0) return null;
      this.nextValue = this.value;
      this.$refs.animator.onStart();
    });
  },
};
</script>
