<template>
  <component :is="tag">
    <slot :targetNumber="targetNumber">
      {{ targetNumber }}
    </slot>
  </component>
</template>

<script>
import { easeOutQuad } from '@shared/modules/Easing';

/**
 * @example
 * <NumberAnimator :min="0" :max="500" :duration="500" v-slot="{targetNumber}">
 *  {{targetNumber}}
 * </NumberAnimator>
 */

export default {
  data: () => ({
    startTime: -1,
    targetNumber: 0,
  }),
  props: {
    min: {
      default: 0,
      type: Number,
    },
    max: {
      default: 100,
      type: Number,
    },
    duration: {
      default: 1000,
      type: Number,
    },
    tag: {
      default: 'div',
    },
  },
  methods: {
    onStart() {
      this.startTime = -1;
      this.onAnimate();
    },
    onAnimate() {
      window.requestAnimationFrame(this.calculateFrame.bind(this));
    },
    calculateFrame(timestamp) {
      if (this.startTime === -1) this.startTime = timestamp;
      const progress = timestamp - this.startTime; // from 0 to diff
      this.targetNumber = easeOutQuad(progress, this.min, this.max, this.duration);
      if (progress < this.duration) {
        this.onAnimate();
      } else {
        this.targetNumber = this.max;
      }
    },
  },
};
</script>
