<template>
  <div my-page-title>
    <div class="left">
      <color-button type="white" class="btn-back" @click="onBack">
        <svg-angle />
      </color-button>
      <slot name="left"></slot>
    </div>
    <div class="center-margin" />
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgAngle from '@shared/graphics/svg-angle.vue';

export default {
  components: { ColorButton, SvgAngle },
  methods: {
    onBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[my-page-title] {.h(110);border-bottom: solid 3px #f2f4f7;.flex;
  > .left:not(.clear) {.flex;flex-direction: row;.flex-jc(center);.flex-ai(center);.fs(20);.lh(23);.bold}
  > .right:not(.clear) {.flex;flex-direction: row;.flex-jc(center);.flex-ai(center);}
  > .center-margin {flex-grow: 1;}
  .btn-back {.hide;
    svg { .t-r(90deg)}
  }
  @media (@tp-down) {
    .pl(20);.pr(20);
    .btn-back {display: inline-block;}
  }
}
</style>
