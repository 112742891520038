<template>
  <div no-content>
    <img :src="src" :alt="alt" />
    <p v-html="_text">
    </p>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    _text() {
      return this.$slots?.default?.[0]?.text || this.text;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[no-content] {.w(100%);.min-h(200);.flex;.flex-column;.flex-ai(center);.flex-jc(center);
  > img {.mb(20)}
  > p {.fs(14);.lh(21);.noto;text-align: center;}
}
</style>
