<template>
  <PointBuyModalView :billingProducts="billingProducts" :paymentMethods="_paymentMethods" :upPoint="upPoint" @close="$emit('close')" @pointBuy="pointBuy"/>
</template>

<script>
import PointBuyModalView from '@/views/components/coaching/PointBuyModalView.vue';
import { payment, paymentMethods } from '@/modules/Payment';
import { CAUSE_LESSON_ORDERS } from '@/constants/payment';

export default {
  components: {
    PointBuyModalView
  },
  data: () => ({
    billingProducts: [],
  }),
  computed: {
    myInfo() {
      return /** @type { MyInfo } */  this.$store?.state?.auth?.myInfo;
    },
    upPoint() {
      return this.myInfo?.wallet?.upBalanceAmount || 0;
    },
    _paymentMethods() {
      return paymentMethods;
    }
  },
  methods: {
    async _getBillingProducts() {
      this.billingProducts = await this.$services.coaching.getBillingProducts();
    },
    pointBuy(product, paymentMethod) {
      const productId = product?.cashProductId;

      if (!productId || !paymentMethod) return;
      payment.setAfterHandler(this.afterBuy.bind(this));
      payment.purchasePoint(productId, paymentMethod);
    },
    closeModal() {
      this.$emit('close');
      if (payment.afterHandler === this.afterBuy.bind(this)) payment.setAfterHandler(null);
    },
    async afterBuy() {
      if (this.$store?.state?.point?.pointBuyCause?.type === CAUSE_LESSON_ORDERS) {
        const { neededPrice, purchaseLessonArg, coachId } = this.$store.state?.point?.pointBuyCause;
        if (this.upPoint >= neededPrice) {
          await this.$services.coaching.purchaseLesson(purchaseLessonArg);
          await this.$services.chat.openChatWithCoach(this, coachId);
          this.$store.commit('point/clearPointBuyCause');
        }
      }
      this.$emit('close');
    }
  },
  mounted() {
    this._getBillingProducts();
  }
};
</script>
