<template>
  <div :class="[_class]" filled-badge @click="$emit('click')">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FilledBadge.vue',
  props: {
    color: {
      type: String,
      default: 'gray',
    },
  },
  computed: {
    _class() {
      return `filled-badge--color-${this.color}`;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

@badge-colors: {
  @1: {
    @bg-color: #000;
    @font-color: #fff;
    @text: black;
  }
  @2: {
    @bg-color: #ebebf0;
    @font-color: #000;
    @text: gray;
  }
  @3: {
    @bg-color: #ebebf0;
    @font-color: #000;
    @text: white;
  }
  @4: {
    @bg-color: #df3a3a;
    @font-color: #fff;
    @text: red;
  }
  @5: {
    @bg-color: #8300ff;
    @font-color: #fff;
    @text: purple;
  }
  @6: {
    @bg-color: #ffc70e;
    @font-color: #000;
    @text: yellow;
  }
  @7: {
    @bg-color: #ea9135;
    @font-color: #fff;
    @text: orange;
  }
  @8: {
    @bg-color: #7e2670;
    @font-color: #fff;
    @text: dark-magenta;
  }
  @9: {
    @bg-color: #4f7e8d;
    @font-color: #fff;
    @text: dark-cyan;
  }
  @10: {
    @bg-color: #1e90ff;
    @font-color: #fff;
    @text: dodger-blue;
  }
  @11: {
    @bg-color: #35a9ea;
    @font-color: #fff;
    @text: light-blue;
  }
}

[filled-badge] { .inline-flex; .h(22); .justify-center; .items-center; .p(0, 8); .br(12); .chakra;
  each(@badge-colors, {
    @text: @value[@text];
    &.filled-badge--color-@{text} {
      .bgc(@value[@bg-color]);
      .c(@value[@font-color]);
    }
  })
}
</style>
