<template>
  <Modal class="modal" point-buy-modal data-cy="point-buy-modal" @close="close">
    <template v-slot:title>
      <div class="header-inner">
        <h2 class="header-title-text">{{ $t('coaching.PT_store') }}</h2>
        <div class="header-my-point">
          <span class="my-point-text">보유 중 :</span>
          <AutoNumberAnimator v-slot="{targetNumber}" :duration="2000" :value="upPoint">
            <PointIcon :show-currency="false" :value="targetNumber" shape="white">
            </PointIcon>
          </AutoNumberAnimator>
        </div>
      </div>
      <hr class="divider">
    </template>
    <div class="point-buy-content">
      <div class="point-product-list">
        <div v-for="product in billingProducts" :key="product.cashProductId" :class="{selected: (product && product.cashProductId) === (chosenProduct && chosenProduct.cashProductId) }" class="product-item" @click="chooseProduct(product)">
          <div class="product-item__point">
            <PointIcon :value="product.point" shape="white"></PointIcon>
            <FilledBadge v-if="recommendedPoint.includes(product.point)" class="recommended-product" color="red">
              <span>HOT</span>
            </FilledBadge>
          </div>
          <div class="product-item__price">
            <AutoNumberAnimator v-slot="{targetNumber}" :duration="2000" :value="product.price">
              <span>{{ $t('coaching.PC_store_cost2', { amount: numberFormat(targetNumber) }) }}</span>
            </AutoNumberAnimator>
          </div>
        </div>
      </div>
      <div class="point-result-panel">
        <div class="buy-price">
          <span class="buy-price-text">결제 금액 :</span>
          <span class="buy-price-number">{{ $t('coaching.PC_store_cost2', { amount: numberFormat(resultPrice) }) }}</span>
        </div>
        <div class="result-point">
          <span class="result-point-text">충전 후 :</span>
          <PointIcon :showCurrency="false" :value="resultPoint" class="result-point-number">
          </PointIcon>
        </div>
      </div>
      <hr class="divider" />
      <div class=" billing-methods">
        <div v-for="methodItem in  paymentMethods" :key="methodItem.key" :class="{'selected': methodItem.key === paymentMethod}" class="payment-item" @click="paymentMethod = methodItem.key">
          <img :src="`/img/icon/${methodItem.icon}`" alt="payment-method-icon">
          <span>{{ methodItem.text }}</span>
        </div>
      </div>
      <div class="point-buy">
        <PrimaryButton class="btn" @click="buyBtnHandler">충전하기</PrimaryButton>
      </div>
      <div class="terms">
        <div class="terms-top" @click="termsDetailHandler">
          <img
            :class="['detail-btn', showTermsDetail ? 'active' : '']"
            alt="arrow-icon"
            src="/img/icon/icon-arrow-down-dark.svg"
          />
          <p class="terms-top-text">아래 이용 안내 및 결제 진행에 동의합니다.</p>
        </div>
        <div v-show="showTermsDetail" class="terms-detail">
          <p class="terms-text">·충전된 UP포인트의 유효기간은 최초 충전 시점으로부터 5년입니다.</p>
          <p class="terms-text">·결제 금액은 부가세(VAT) 포함입니다.</p>
          <p class="terms-text">·사용되지 않은 UP포인트는 7일 이내 청약 철회가 가능합니다.</p>
          <p class="terms-text">·만 14세 이상 회원만 충전 가능합니다.</p>
          <p class="terms-text">·미성년자 회원이 충전할 경우, 법정대리인의 동의를 반드시 얻어야 합니다.</p>
          <p class="terms-text">·자세한 내용은 <a class="terms-text--link" href="/policy/general-terms.html" target="_blank">서비스 이용 약관</a> 에서 확인하실 수 있습니다.
          </p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import { commaDecimal } from '@shared/utils/numberUtils';
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';
import PointIcon from '@/views/components/coaching/PointIcon.vue';
import AutoNumberAnimator from '@/views/components/coaching/AutoNumberAnimator.vue';
import FilledBadge from '@/views/components/coaching/FilledBadge.vue';

/** 추천 포인트. 서버에서 내려 주는 값은 아니며, 정책 상 결정 됨. */
const RECOMMENDED_POINT = 3000;

export default {
  name: 'PointBuyModal',
  components: {
    PrimaryButton,
    FilledBadge,
    AutoNumberAnimator,
    Modal,
    PointIcon,
  },
  props: {
    upPoint: { type: Number, default: 0 },
    billingProducts: { default: () => [] },
    paymentMethods: { default: () => [] },
  },
  data: () => ({
    chosenProduct: null,
    recommendedPoint: [RECOMMENDED_POINT],
    paymentMethod: 'payment-method-credit',
    showTermsDetail: false,
  }),
  watch: {
    billingProducts(newVal) {
      if (!newVal) return;
      for (let i = 0; i < newVal.length; i++) {
        if (newVal[i]?.point === RECOMMENDED_POINT) {
          this.chosenProduct = newVal[i];
          break;
        }
      }
    },
  },
  computed: {
    resultPrice() {
      if (!this.chosenProduct) return 0;
      return this.chosenProduct.price;
    },
    resultPoint() {
      if (!this.chosenProduct) return this.upPoint;
      return this.chosenProduct.point + this.upPoint;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    chooseProduct(product) {
      this.chosenProduct = product;
      /** GA 전자상거래 추적코드 */
      this.$gtag.event('click_point_product', {
        'event_category': 'point_buy_modal',
        'event_label': 'choose_point_product',
        'value': this.chosenProduct?.price,
      });
    },
    numberFormat(num) {
      return commaDecimal(Math.floor(num));
    },
    termsDetailHandler() {
      this.showTermsDetail = !this.showTermsDetail;
    },
    buyBtnHandler() {
      /** GA 전자상거래 추적코드 */
      this.$gtag.event('click_point_buy', {
        'event_category': 'point_buy_modal',
        'event_label': 'buy_point',
        'value': this.chosenProduct?.price,
      });
      this.$emit('pointBuy', this.chosenProduct, this.paymentMethod);
    },
  },
  mounted() {
    /** GA 전자상거래 추적코드 */
    this.$gtag.event('point_modal_view', {
      'event_category': 'point_buy_modal',
      'event_label': 'open',
    });
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[point-buy-modal] {
  &.modal {
    > .panel {.w(560);}
  }
  .divider { .m(20, 0); .w(100%); .h(1); .bgc(#faf2ff);}
  .header-inner { .flex;
    .header-title-text {align-self: center; .fs(16);}
    .header-my-point { .flex; .items-center; .bgc(#faf2ff); .br(4); .h(32); .ml(12); .p(0, 10);
      .my-point-text {.bold; .c(#8300ff); .mr(8); display: none;}
      .white {.bgc(unset); .p(0); }
      .currency {.bold;}
    }
  }
  .point-buy-content {
    .point-product-list { .max-h(236); overflow: auto;
      .product-item { .flex; .items-center; .space-between; .h(60); .p(0, 24); .br(4); cursor: pointer; .bgc(rgba(235, 235, 240, 0.5)); .mb(8); border: solid 1px rgba(255, 255, 255, 0); border-left: solid 8px #8300ff;
        &:hover {border: 1px solid rgba(131, 0, 255, 0.2); border-left: solid 8px #8300ff;}
        &.selected { border: solid 2px #8300ff; border-left: solid 8px #8300ff; }
        .product-item__point { .flex; .items-center;
          .white {min-width: 136px; .br(8);
            .currency {.bold;}
          }
          .recommended-product { .ml(6);}
        }
        .product-item__price { .fs(18); .bold;}
      }
    }
    .point-result-panel { .flex; .space-between; .h(80); .p(16); .mt(24); .bgc(#191919);
      .buy-price { .flex; .c(#fff); .bold;
        .buy-price-text {.fs(14); align-self: center; }
        .buy-price-number {.fs(16); align-self: center; .ml(8);}
      }
      .result-price { .flex; .c(#fff);
        .result-price-text {}
        .result-price-number {}
      }
      .result-point {.flex; .items-center; .p(0, 12); .bgc(#8300ff); .c(#fff); .bold;
        .result-point-text { .mr(2);}
        .result-point-number { .br(4);
          img {
            .w(32); .h(32);}
        }
      }
    }
    .billing-methods { .flex; flex-wrap: wrap;
      .payment-item { .flex; .w(calc(50% - 6px)); .h(52); border: 1px solid #ebebf0; .br(4); .mb(12); cursor: pointer;
        .items-center; .justify-center; text-align: center;
        img {.mr(4); .wh(20)}
        &:first-of-type { flex-basis: 100%; .w(100%); }
        &:nth-child(2) {.mr(12);}
        &:nth-child(4) {.mr(12);}
        &.selected {border: solid 1px #8300ff; .bold}
      }
    }
    .point-buy { .mt(20); text-align: center;
      .btn {.w(100%); .h(60); .fs(20)}
    }
    .terms { border-radius: 0 0 20px 20px; .p(20); .mt(40); .bgc(rgba(235, 235, 240, 0.5)); .w(calc(100% + 80px)); .ml(-40);
      .terms-top { .flex; .items-center;
        .terms-top-text {cursor: pointer; .c(#787781); .fs(14); }
        .detail-btn {.w(32); .h(32); .bgc(#fff); .br(8); .o(0.4); .mr(4); .p(4); cursor: pointer;
          &.active { transform: rotate(180deg);}
        }
      }
      .terms-detail { .ml(32);
        .terms-text { .fs(12); .o(0.8); .c(#a4a3ae);
          .terms-text--link {.c(#2c81ff); text-decoration: underline;}
        }
      }
    }
  }
  .payment-item:not(:first-child) span {
    .max-w(140);display: inline-block;word-break: break-word;
  }

  @media (@tp-up) {
    .payment-item:not(:first-child) span {width: unset;.max-w(unset);}
    .header-inner {
      .header-title-text { .fs(20);
      }
      .header-my-point {
        .my-point-text {display: unset;}
      }
    }
    .point-buy-content {
      point-product-list {
        .product-item {
          .product-item__price {.fs(22);}
        }
      }
      .point-result-panel {
        .buy-price {
          .buy-price-number {.fs(22); .ml(20);}
        }
      }

      .point-buy {
        .btn {.w(360);}
      }
    }
  }
}

</style>
