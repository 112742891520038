<template>
  <div coach-my-page-content-layout>
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-my-page-content-layout] {
  .bgc(#fff);.c(#000);.p(0, 30, 48, 30);
  @media (@tl-down) {
    .p(0, 0, 48, 0)
  }
}
</style>
