<script>
import StatusBadge from '@/views/components/coaching/sales/StatusBadge.vue';

export default {
  extends: StatusBadge,
  computed: {
    badgeData() {
      return {
        DEPOSIT: ['green', '획득'],
        WITHDRAWAL: ['red', '사용'],
        LVUP_CASH_REWARD: ['purple', '보상']
      };
    },
    colorClass() {
      return this.badgeData[this.value]?.[0] ?? 'green';
    },
    statusText() {
      return this.badgeData[this.value]?.[1] ?? '-';
    }
  }
};
</script>